<template>
  <getecma-single-content-layout v-loading="fullscreenLoading" content-class="scroll pe--xxl">
    <template v-if="user" #content>
      <getecma-breadcrumb :items="itemsBreadcrumb" class="mb--md mt--xl" />
      <div v-if="user.id">
        <getecma-header size="lg">Editar Usuário</getecma-header>
        <getecma-divider class="my--md" />
        <getecma-user-edit-information @save="save" />
      </div>
      <div v-if="!user_id">
        <getecma-header size="lg">Adicionar Usuário</getecma-header>
        <getecma-divider class="my--md" />
        <getecma-user-create-information :existing-users-email="existingUsersEmail" :total-users="totalUsers" @create="save" />
      </div>
    </template>
  </getecma-single-content-layout>
</template>

<script>
import { toastError, toastSuccess, toastInfo } from '@/services/toastService';
import { goToOpenUser, goToUserForbiddenPage } from '@/modules/user/user.routes'; // eslint-disable-line
import { getters } from '@/modules/user/user.store';
import { getUserById, updateUserInformation, createNewUser, checkUserByEmail } from '@/modules/user/user.service';
import { USERS_ROLES, USERS_URL } from '@/modules/user/user.constants';

import GetecmaSingleContentLayout from '@/layouts/SingleContentLayout.vue';
import GetecmaUserEditInformation from '@/modules/user/components/UserEditComponent.vue';
import GetecmaUserCreateInformation from '@/modules/user/components/UserCreateComponent.vue';
import GetecmaBreadcrumb from '@/components/breadcrumb/Breadcrumb.vue';
import GetecmaDivider from '@/components/menu/components/Divider.vue';

export default {
  name: 'GetecmaUserEditPage',
  components: {
    GetecmaSingleContentLayout,
    GetecmaUserEditInformation,
    GetecmaUserCreateInformation,
    GetecmaBreadcrumb,
    GetecmaDivider,
  },
  provide() {
    const userEditVm = {};
    Object.defineProperty(userEditVm, 'user', {
      get: () => this.user,
    });
    return { userEditVm };
  },
  data() {
    return {
      itemsBreadcrumb: [
        { name: 'Dashboard', path: '/' },
        { name: 'Usuários', path: USERS_URL.path },
        { name: 'Adicionar', path: this.$route.path },
      ],
      existingUsersEmail: [],
      totalUsers: 0,
      currentUser: getters.getUser(),
      user: null,
      createdUser: null,
      resetUserPassword: false,
      fullscreenLoading: false,
    };
  },
  computed: {
    user_id() {
      return this.$route.params?.id;
    },
  },
  mounted() {
    if (this.user_id) {
      this.itemsBreadcrumb[2].name = 'Editar';
      if (this.currentUser.permission < USERS_ROLES.ADMIN) goToUserForbiddenPage(this.$router, false);
      this.onFetch();
    } else {
      if (this.currentUser.permission < USERS_ROLES.ADMIN) goToUserForbiddenPage(this.$router, true);
      this.onFetch();
    }
  },
  methods: {
    onFetch() {
      if (this.user_id) {
        getUserById(this.user_id)
          .then(data => {
            this.user = data;
          })
          .catch(() => toastError('Erro ao obter usuário por ID'));
      } else {
        this.user = {
          name: '',
          last_name: '',
          email: '',
          position: '',
          permission: '0',
        };
      }
    },
    save() {
      if (this.user_id) {
        this.fullscreenLoading = true;
        updateUserInformation(this.user)
          .then(data => {
            this.fullscreenLoading = false;
            toastSuccess('Usuário salvo!');
            goToOpenUser(this.$router, data);
          })
          .catch(() => {
            toastError('Erro ao atualizar o perfil do usuário');
            this.fullscreenLoading = false;
          });
      } else {
        this.checkIfEmailExistis(this.user);
      }
    },
    onCreateNewUser(user) {
      createNewUser(user)
        .then(data => {
          goToOpenUser(this.$router, data);
        })
        .catch(() => {
          toastError('Este email já está associado à um usuário');
        });
    },
    checkIfEmailExistis(user) {
      checkUserByEmail(user.email)
        .then(data => {
          if (data.exist) {
            if (!data.deleted) {
              toastInfo('Este email já está associado à um usuário');
            } else {
              this.createdUser = data;
            }
          } else {
            this.onCreateNewUser(user);
          }
        })
        .catch(() => toastError('Erro ao criar um novo usuário'));
    },
  },
};
</script>
