<template>
  <div>
    <getecma-form v-if="user" :submit="create">
      <div class="row mt--xl">
        <div class="form-field col-18 col-lg-9">
          <getecma-input
            v-model="user.name"
            label="Nome*"
            name="nome"
            type="text"
            rules="required"
            placeholder="ex.: Lucas" />
        </div>

        <div class="form-field col-18 col-lg-9 mt--md">
          <getecma-input
            v-model="user.email"
            label="E-mail*'"
            name="e-mail"
            type="text"
            rules="required"
            placeholder="ex.: lucas@email.com" />
        </div>

        <div class="form-field col-18 col-lg-9 mt--md">
          <label class="mb--md fs--lg fw--strong" for="password">Senha</label>
          <getecma-input
            id="password"
            v-model="user.password"
            name="senha"
            type="password"
            class="password"
            autocomplete="off"
            rules="min:8"
            placeholder="********" />
        </div>
        <div class="form-field col-18 col-lg-9 mt--md">
          <label class="mb--md fs--lg fw--strong" for="confirm_password">Confirmar senha</label>
          <getecma-input
            id="confirm_password"
            v-model="user.password2"
            name="confirmação de senha"
            type="password"
            class="password"
            autocomplete="off"
            rules="min:8"
            placeholder="********" />
        </div>
        <getecma-small class="d-flex mt--lg text-success" align="left">*Sua senha deve possuir pelo menos 8 caracteres</getecma-small>
        <getecma-small class="d-flex text-success" align="left">*Sua senha deve possuir números e caracteres especiais</getecma-small>

        <div class="form-field col-18 col-lg-9 mt--md">
          <fieldset class="form-group">
            <label for="permission" class="fs--lg fw--strong mb--sm me--xl w-100">Permissão</label>
            <el-tooltip effect="dark" :content="infoPermission[0]" placement="bottom">
              <div id="permission" class="form-check form-check-inline">
                <label class="fs--md">
                  <input
                    id="viewerOption"
                    v-model="user.permission"
                    type="radio"
                    class="form-check-input"
                    name="optionsRadios"
                    value="0">
                  Visualizador
                </label>
              </div>
            </el-tooltip>
            <el-tooltip effect="dark" :content="infoPermission[1]" placement="bottom">
              <div class="form-check form-check-inline">
                <label class="fs--md">
                  <input
                    id="writerOption"
                    v-model="user.permission"
                    type="radio"
                    class="form-check-input"
                    name="optionsRadios"
                    value="1">
                  Administrador
                </label>
              </div>
            </el-tooltip>
          </fieldset>
        </div>
      </div>

      <div class="mt--xl mb--md d--flex justify-content-end">
        <getecma-button
          class="fs--xs"
          :round="false"
          size="lg"
          @click="goHistoryBack()">
          Cancelar
        </getecma-button>
        <getecma-button
          native-type="submit"
          class="fs--xs ms--md"
          :round="false"
          bg-color="success"
          size="lg">
          Criar
        </getecma-button>
      </div>
    </getecma-form>
  </div>
</template>

<script>
import { toastError } from '@/services/toastService';
import { goHistoryBack } from '@/router/route.service';
import { getters } from '@/modules/user/user.store';

export default {
  name: 'GetecmaUserCreateInformation',
  components: {
  },
  inject: ['userEditVm'],
  props: {
    existingUsersEmail: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      user: this.userEditVm.user,
      performer: getters.getUser(),
      infoPermission: [
        'Acesso limitado para apenas visualizar o calendário',
        'Acesso liberado de visualizar e editar o calendário, e gerenciamento de clientes e frota',
      ],
    };
  },
  methods: {
    goHistoryBack,
    create() {
      if (this.user.password !== this.user.password2) {
        toastError('As senhas não coincidem!');
      } else {
        this.$emit('create');
      }
    },
  },
};
</script>
