<template>
  <getecma-form v-if="user" :submit="save">

    <fieldset class="form-group mt--xl">
      <label for="permission" class="fs--lg fw--strong mb--sm me--xl">
        Permissão:
      </label>
      <div class="form-check form-check-inline">
        <label class="fs--md">
          <input
            id="writerOption"
            v-model="user.permission"
            type="radio"
            class="form-check-input"
            name="optionsRadios"
            value="0">
          Visualizador
        </label>
      </div>
      <div class="form-check form-check-inline">
        <label class="fs--md">
          <input
            id="adminOption"
            v-model="user.permission"
            type="radio"
            class="form-check-input"
            name="optionsRadios"
            value="1">
          Administrador
        </label>
      </div>
      <div>
        <getecma-icon icon="info-circle-fill" size="sm" font-size="15" class="opacity--70 mx--md" />
        <getecma-small color="success">{{ infoPermissionText }}</getecma-small>
      </div>
    </fieldset>
    <div class="mt--xl mb--md d--flex justify-content-end">
      <getecma-button
        size="xl"
        :round="false"
        class="fs--sm"
        @click="goHistoryBack()">
        Cancelar
      </getecma-button>
      <getecma-button
        native-type="submit"
        class="ms--md fs--sm"
        bg-color="success"
        :round="false"
        size="xl"
        :disabled="user.permission === 3 && user.id !== performer.id">
        Salvar
      </getecma-button>
    </div>
  </getecma-form>
</template>

<script>

import { goHistoryBack } from '@/router/route.service';
import { getters } from '@/modules/user/user.store';

export default {
  name: 'GetecmaUserEditInformation',
  inject: ['userEditVm'],
  data() {
    return {
      performer: getters.getUser(),
      user: this.userEditVm.user,
      company_owner: this.userEditVm.user.company_owner,
      infoPermission: [
        'Acesso limitado para apenas visualizar o calendário',
        'Acesso liberado de visualizar e editar o calendário, e gerenciamento de clientes e frota',
      ],
    };
  },
  computed: {
    infoPermissionText() {
      return this.infoPermission[this.user.permission];
    },
  },
  methods: {
    goHistoryBack,
    save() {
      this.$emit('save');
    },
  },
};
</script>
